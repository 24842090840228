* {
  box-sizing: border-box;
}

body {
  font-family: "Nunito", sans-serif;
}

.header .logo {
  font-family: "Playfair Display", serif; /* Use the new font for the title */
  font-size: 1.5em;
  font-weight: 700;
}

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: inherit;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
