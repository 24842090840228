.card {
  width: 90%;
  height: 80%;
  margin: auto;
  min-height: 512px;
  box-shadow: 0px 35px 70px 0px rgba(158, 158, 158, 0.65),
    0px 30px 40px -10px rgba(223, 188, 188, 0.55);
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}

.header {
  display: flex;
  padding: 48px;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.logo {
  font-weight: bold;
  font-size: 1.5em;
  transition: opacity 0.05s ease;
}

.logo:hover {
  opacity: 0.75;
}

.social {
  display: flex;
  gap: 12px; /* Decrease gap between icons */
  justify-content: center;
  margin-top: auto;
}

.social a {
  display: inline-block;
  transition: opacity 0.3s ease;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 36px; /* Increase icon size */
  height: 36px;
  fill: #c2a6a2; /* Match color to your design */
}

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}

.content .title-holder {
  color: #000000;
  text-align: center;
  margin-bottom: 24px;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
}

.content .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
}

.title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 8px; /* Adjust space below title */
}

.tagline {
  font-size: 16px;
  color: #333333;
  margin-bottom: 16px; /* Space below tagline */
  text-align: center;
}

.notify-form {
  margin-bottom: 48px;
}

.input-group {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.input-cue {
  font-size: 1.2em;
  padding: 0 8px;
  color: #b08d88;
}

.email-input {
  padding: 12px 16px;
  border: none;
  outline: none;
  font-size: 1em;
  width: 100%;
  text-align: left;
  border-radius: 0;
  min-width: 0;
}

.email-input::placeholder {
  text-align: left;
}

.cta {
  display: inline-block;
  padding: 12px 24px;
  background: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  white-space: nowrap;
  color: #000000;
  text-decoration: none;
  margin-top: 16px; /* Space above the button */
}

.cta:hover {
  background-color: #f5e6e4;
}

.explanation {
  font-size: 14px;
  color: #666666;
  margin-top: 8px;
  text-align: center;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 1px dotted #000000;
}

.thank-you {
  font-size: 16px;
  color: #333333;
  text-align: center;
  padding: 16px 16px;
  border-radius: 10px;
  background-color: #f5e6e4; /* Soft background to match theme */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
  margin-top: 24px;
  max-width: 400px;
}
